// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
import "controllers";
import "template";
import Swal from "sweetalert2";
import LocalTime from "local-time";

import "bootstrap";
import "trix";
import "@rails/actiontext";

import "stylesheets/application.scss";
import "trix/dist/trix.css";
import "lib/keenicons/duotone/style.css";
import "lib/keenicons/outline/style.css";
import "lib/keenicons/solid/style.css";
import "@yaireo/tagify/dist/tagify.css";

window?.Turbo?.setConfirmMethod((message) => {
  return new Promise((resolve, _reject) => {
    Swal.fire({
      title: "Você tem certeza?",
      text: message,
      icon: "warning",
      buttonsStyling: false,
      cancelButtonText: "Manter",
      confirmButtonText: "Sim, remover",
      customClass: {
        cancelButton: "btn btn-secondary me-2",
        confirmButton: "btn btn-danger",
      },
      reverseButtons: true,
      showCancelButton: true,
    }).then((confirmed) => {
      resolve(confirmed && confirmed.value == true);
    });
  });
});

LocalTime.start();

window.process = { env: {} };
